(function () {
  class Tariff extends Common {
    constructor() {
      super()
    };

    configure() {
      // by CSS Class
      this.ui.customInputs = ".js-custom-inputs";
      this.ui.jsPrice = ".js-price";
      this.ui.jsDryBulk = ".js-dry-bulk";
      this.ui.jsCargo = ".js-cargo";
      this.ui.jsLock = ".js-lock";
      this.ui.jsFilter = ".js-filter";
      this.ui.jsOtherFilter = ".js-other-filter";
      this.ui.jsLengthFrom = ".js-length-from";
      this.ui.jsLengthTo = ".js-length-to";
      this.ui.jsBeamFrom = ".js-beam-from";
      this.ui.jsBeamTo = ".js-beam-to";
      this.ui.jsDisplacementFrom = ".js-displacement-from";
      this.ui.jsDisplacementTo = ".js-displacement-to";
      this.ui.jsOtherFrom = ".js-other-from";
      this.ui.jsOtherTo = ".js-other-to";
      this.ui.jsCapacity = ".js-capacity";
      this.ui.jsCapacityFrom = ".js-capacity-from";
      this.ui.jsCapacityTo = ".js-capacity-to";
      this.ui.jsPriceII = ".js-price-ii";
      this.ui.jsPriceIII = ".js-price-iii";
      this.ui.jsPriceIV = ".js-price-iv";
      this.ui.jsLabelPriceII = ".js-label-price-ii";
      this.ui.jsLabelPriceIII = ".js-label-price-iii";
      this.ui.jsLabelPriceIV = ".js-label-price-iv";

      //By property
      this.ui.categoryId = "#tariff_category_id";
      this.ui.lockType = "#tariff_lock_type";
      this.ui.price = "#tariff_price";
      this.ui.vesselType = "#tariff_vessel_type";
    };

    loadComponents() {
      this.configure();
      this.loadCategorySelect();
      this.needFormValidation();
      $(this.ui.categoryId).change();
    };

    loadComponentsV2() {
      this.configure();
      this.loadCategorySelectV2();
      this.loadVesselType();
      this.needFormValidation();
      $(this.ui.categoryId).change();
      $(this.ui.vesselType).change();
    };

    loadCategorySelect() {
      var operation = this;
      $(operation.ui.categoryId).on('change', function () {
        var divInputs = $(operation.ui.customInputs + " div.col-12");
        var categorySelected = $(this).val();

        divInputs.each(function (idx, element) {
          $(element).removeClass("d-block").find(":input").removeClass("required");
          $(element).addClass("d-none");
        });

        $(operation.ui.jsPrice).addClass('d-block');

        if (categorySelected == 1) {
          $(operation.ui.jsCargo).addClass('d-block').find(":input").addClass("required");
        } else if (categorySelected == 2) {
          $(operation.ui.jsFilter).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsLock).addClass('d-block').find(":input").addClass("required");
        } else if (categorySelected == 3) {
          $(operation.ui.jsDryBulk).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsCargo).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsLock).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsFilter).addClass('d-block').find(":input").addClass("required");
        } else if (categorySelected == 4 || categorySelected == 8) {
          $(operation.ui.jsCargo).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsLock).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsFilter).addClass('d-block').find(":input").addClass("required");
        } else if (categorySelected == 5 || categorySelected == 9 || categorySelected == 11) {
          $(operation.ui.jsCargo).addClass('d-block').find(":input").addClass("required");
        } else if (categorySelected == 6) {
          $(operation.ui.jsCargo).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsLock).addClass('d-block').find(":input").addClass("required");
        } else if (categorySelected == 7) {
          $(operation.ui.jsCargo).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsFilter).addClass('d-block').find(":input").addClass("required");
        } else if (categorySelected == 10) {
          $(operation.ui.jsCargo).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsLock).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsOtherFrom).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsOtherTo).addClass('d-block');
        }
      });
    };

    hideFields() {
      var operation = this;
      var divInputs = $(operation.ui.customInputs + " div.col-12");

      divInputs.each(function (idx, element) {
        $(element).removeClass("d-block").find(":input").removeClass("required");
        $(element).addClass("d-none");
      });
    };

    loadCategorySelectV2() {
      var operation = this;
      $(operation.ui.categoryId).on('change', function () {
        var categorySelected = $(this).val();
        operation.hideFields();

        $(operation.ui.jsPrice).addClass('d-block').find(":input").addClass("required");
        $(operation.ui.jsPriceII).addClass('d-block').find(":input").addClass("required");
        $(operation.ui.jsCapacityFrom).addClass('d-block').find(":input").addClass("required");
        $(operation.ui.jsCapacityTo).addClass('d-block');

        if (categorySelected == 2) {
          $(operation.ui.jsPriceIII).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsPriceIV).addClass('d-block').find(":input").addClass("required");
        };

        //if (categorySelected == 1 || categorySelected == 2 || categorySelected == 8 || categorySelected == 9 || categorySelected == 11) {
        // $(operation.ui.jsCapacityTo).find(":input").addClass("required");
        //};

        $(operation.ui.vesselType).change();
      });
    };

    loadVesselType() {
      var operation = this;

      $(operation.ui.vesselType).on('change', function () {
        var categorySelected = $(operation.ui.categoryId).find('option:selected').val();
        var vesselTypeSelected = $(this).val();

        if (vesselTypeSelected == 'without_type' && categorySelected == 2) {
          $(operation.ui.jsCapacityFrom).removeClass("d-block").addClass("d-none").find(":input").removeClass("required");
          $(operation.ui.jsCapacityTo).removeClass("d-block").addClass("d-none");
        } else {
          $(operation.ui.jsCapacityFrom).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsCapacityTo).addClass('d-block');
        }

        if (vesselTypeSelected == 'without_type' && categorySelected == 2) {
          $(operation.ui.jsPrice).removeClass('d-block').addClass('d-none');
          $(operation.ui.jsLabelPriceII).html(I18n.t('tariff.teu_refer'));
          $(operation.ui.jsLabelPriceIII).html(I18n.t('tariff.teu_dry'));
          $(operation.ui.jsLabelPriceIV).html(I18n.t('tariff.teu_empty'));
        } else if (categorySelected == 2) {
          $(operation.ui.jsPrice).removeClass('d-none').addClass('d-block');
          $(operation.ui.jsLabelPriceII).html(I18n.t('tariff.capacity_tta'));
          $(operation.ui.jsLabelPriceIII).html(I18n.t('tariff.loaded_ttl'));
          $(operation.ui.jsLabelPriceIV).html(I18n.t('tariff.empty_tte'));
        } else {
          $(operation.ui.jsLabelPriceII).html(I18n.t('tariff.capacity_tariff'));
        };
      });
    };

    showViewToll(categorySelected) {
      this.configure();
      var operation = this;

      if (categorySelected == 1) {
        $(operation.ui.jsCargo).addClass('d-block');
      } else if (categorySelected == 2) {
        $(operation.ui.jsFilter).addClass('d-block');
        $(operation.ui.jsLock).addClass('d-block');
      } else if (categorySelected == 3) {
        $(operation.ui.jsDryBulk).addClass('d-block');
        $(operation.ui.jsCargo).addClass('d-block');
        $(operation.ui.jsLock).addClass('d-block');
        $(operation.ui.jsFilter).addClass('d-block');
      } else if (categorySelected == 4 || categorySelected == 8) {
        $(operation.ui.jsCargo).addClass('d-block');
        $(operation.ui.jsLock).addClass('d-block');
        $(operation.ui.jsFilter).addClass('d-block');
      } else if (categorySelected == 5 || categorySelected == 9 || categorySelected == 11) {
        $(operation.ui.jsCargo).addClass('d-block');
      } else if (categorySelected == 6) {
        $(operation.ui.jsCargo).addClass('d-block');
        $(operation.ui.jsLock).addClass('d-block');
      } else if (categorySelected == 7) {
        $(operation.ui.jsCargo).addClass('d-block');
        $(operation.ui.jsFilter).addClass('d-block');
      } else if (categorySelected == 10) {
        $(operation.ui.jsCargo).addClass('d-block');
        $(operation.ui.jsLock).addClass('d-block');
        $(operation.ui.jsOtherFrom).addClass('d-block');
        $(operation.ui.jsOtherTo).addClass('d-block');
      }
    };

    showViewTollV2(categorySelected, vesselType) {
      this.configure();
      var operation = this;

      if (categorySelected == 2) {
        if (vesselType == 'neo') {
          $(operation.ui.jsCapacity).removeClass('d-none');
        } else if (vesselType == 'without_type') {
          $(operation.ui.jsPrice).removeClass('d-block').addClass('d-none');
          $(operation.ui.jsLabelPriceII).html(I18n.t('tariff.teu_refer'));
          $(operation.ui.jsLabelPriceIII).html(I18n.t('tariff.teu_dry'));
          $(operation.ui.jsLabelPriceIV).html(I18n.t('tariff.teu_empty'));
        };

        $(operation.ui.jsPriceII).addClass('d-block');
        $(operation.ui.jsPriceIII).addClass('d-block');
        $(operation.ui.jsPriceIV).addClass('d-block');
      } else {
        $(operation.ui.jsCapacity).removeClass('d-none');
        $(operation.ui.jsLabelPriceII).html(I18n.t('tariff.capacity_tariff'));
        $(operation.ui.jsPriceII).addClass('d-block');
      }
    }

    loadDynamicSelect(selector, equalTo) {
      var operation = this;
      operation.configure();

      $(selector).on('change', function () {
        var divInputs = $(operation.ui.customInputs + " div.col-12");
        var lockTypeSelected = $(this).val();

        divInputs.each(function (idx, element) {
          $(element).removeClass("d-block").find(":input").removeClass("required");
          $(element).addClass("d-none");
        });

        $(operation.ui.jsPrice).addClass('d-block');

        if ($.inArray(lockTypeSelected, equalTo) >= 0) {
          divInputs.each(function (idx, element) {
            $(element).addClass("d-block");
          });
        }
      });
    };

    showDynamicSelect(equalTo, lockTypeSelected) {
      this.configure();
      var divInputs = $(this.ui.customInputs + " div.col-12");

      if ($.inArray(lockTypeSelected, equalTo) >= 0) {
        divInputs.each(function (idx, element) {
          $(element).addClass("d-block");
        });
      }
    };
  };

  window.Tariff = Tariff;
})();
