// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require.context('../images', true)

import "../stylesheets/application.scss"
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
Rails.start()
ActiveStorage.start()

import "channels"
import 'bootstrap'
import 'jquery-slimscroll'
import 'node-waves'
import screenfull from 'screenfull';
window.screenfull = screenfull;
import 'jquery-validation'
window.jQuery = $;
window.$ = $;
var moment = require('moment')
global.moment = moment;
window.moment = moment;
import 'select2';
import 'sweetalert';
import bootbox from 'bootbox'
global.bootbox = bootbox;
window.bootbox = bootbox;

var I18n = require('i18n-js')
global.I18n = I18n;
window.I18n = I18n;

import '../src/i18n/translations'
import '../src/common'
import '../src/tariffs/tariff'
import '../src/calculators/calculator'
import '../src/calculators/calculator_v1'
require('src/app.js')
require('src/plugins/bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker.js')
require('src/plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css')
require("trix")
require("@rails/actiontext")
require("inputmask/dist/jquery.inputmask")
require('inputmask/dist/bindings/inputmask.binding')
require("@nathanvda/cocoon")
