const moment = require("moment");

(function () {
  class Calculator extends Common {
    constructor() {
      super()
    };

    configure() {
      // by CSS Class
      this.ui.jsInputsBox = ".js-inputs-box";
      this.ui.jsCustomInputs = ".js-custom-inputs";
      this.ui.jsPcums = ".js-pcums";
      this.ui.jsFullTeus = ".js-full-teus-carried";
      this.ui.jsTeuCapacity = ".js-teu-capacity";
      this.ui.jsAcpVisibility = ".js-acp-visibility";
      this.ui.jsLoyaltyProgram = ".js-loyalty-program";
      // this.ui.jsNewLoyaltyProgram = '.js-new-loyalty-program';
      this.ui.jsVesselDwt = ".js-vessel-dwt";
      this.ui.jsDryBulk = ".js-dry-bulk";
      this.ui.jsCargoWeight = ".js-cargo-weight";
      this.ui.jsNewCargoWeight = ".js-new-cargo-weight";
      this.ui.jsCargoCapacity = ".js-cargo-capacity";
      this.ui.jsGrt = ".js-grt";
      this.ui.jsBerthCapacity = ".js-berth-capacity";
      this.ui.jsPaxCapacity = ".js-pax-capacity";
      this.ui.jsDaylightTransit = ".js-daylight-transit";
      this.ui.jsVesselName = ".js-vessel-name";
      this.ui.jsVesselImo = ".js-vessel-imo";
      this.ui.jsTeuEmpty = ".js-teu-empty";
      this.ui.jsTeuReefer = ".js-teu-reefer";
      this.ui.jsTeuDry = ".js-teu-dry";
      this.ui.jsShowCompare = ".js-show-compare";

      // by ID
      this.ui.imo = "#calculator_imo";
      this.ui.name = "#calculator_name";
      this.ui.renderFormCalculator = "#calculator-form";
      this.ui.lockType = "#calculator_lock_type";
      this.ui.categoryId = "#calculator_category_id";
      this.ui.idLength = "#calculator_length";
      this.ui.beam = "#calculator_beam";
      this.ui.draft = "#calculator_tfw_draft";
      this.ui.oil_capacity = "#calculator_oil_capacity";
      this.ui.displacement = "#calculator_displacement";
      this.ui.teuCapacity = "#calculator_teu_capacity";
      this.ui.fullTeusCarried = "#calculator_full_teus_carried";
      this.ui.teuEmpty = "#calculator_teu_empty";
      this.ui.teuReefer = "#calculator_teu_reefer";
      this.ui.teuDry = "#calculator_teu_dry";
      this.ui.cargoType = 'input[name="calculator[cargo_type]"]';
      this.ui.cargoWeight = "#calculator_cargo_weight";
      this.ui.btnSubmit = "#btn-submit-calculator";
      this.ui.calculateForm = "form#FormCalculation";
      this.ui.skipAcpVisibility = "#calculator_skip_exceeds_acp_visibility";
      this.ui.skipTransitReservation = "#calculator_skip_transit_reservation";
      this.ui.skipDaylightTransit = "#calculator_skip_pax_daylight_transit";
      this.ui.skipFirstTransit = "#calculator_skip_first_transit";
      this.ui.resultSkipAcpVisibility = "#result-skip-acp-visibility";
      this.ui.resultSkipTransitReservation = "#result-skip-transit-reservation";
      this.ui.resultSkipDaylightTransit = "#result-skip-daylight-transit";
      this.ui.resultSkipFirstTransit = "#result-skip-first-transit";
      this.ui.chkSkipAcpVisibility = "#skip_exceeds_acp_visibility";
      this.ui.chkSkipTransitReservation = "#skip_transit_reservation";
      this.ui.chkSkipDaylightTransit = "#skip_pax_daylight_transit";
      this.ui.chkSkipFirstTransit = "#skip_first_transit";
      this.ui.openOptional = "#calculator_open_optional";
      this.ui.calculateLink = "#calculate-link";
      this.ui.calculateLink1 = "#calculate-link-1";
      this.ui.etaDate = "#calculator_eta";
      this.ui.showCompare = "#calculator_show_compare";
      this.ui.cat12 = "#cat-12";
      this.ui.cat13 = "#cat-13";
      this.ui.cat14 = "#cat-14";
    };

    calculateLink() {
      var operation = this;
      $(operation.ui.calculateLink).on('click', (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();

        if ($(operation.ui.jsFormValidation).valid()) {
          $(operation.ui.calculateLink).html("Processing...");
        }

        $(operation.ui.btnSubmit).click();
      });

      $(operation.ui.calculateLink1).on('click', (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();

        if ($(operation.ui.jsFormValidation).valid()) {
          $(operation.ui.calculateLink).html("Processing...");
        }

        $(operation.ui.btnSubmit).click();
      });
    };

    submitForm() {
      const operation = this;

      $(operation.ui.calculateForm).bind("ajax:beforeSend", function (event, data, status, xhr) {
        operation.pageLoaderIn();
        return true
      })
    }

    loadComponents() {
      this.configure();
      this.needFormValidationCustom();
      this.pageLoaderOut();
      this.loadDatepicker(); //({ start_date_from: moment().add(2, 'days') });
      this.vesselCategorySelect();
      this.setLockType();
      this.cargoTypeHandler();
      this.loadTooltips();
      this.calculateLink();
      this.submitForm();
      this.maskedNumber();
      this.quickCalculation();
      this.formatNumberToCurrency();
      this.changeDatePicker();
      this.handleShowCompare();
      $(this.ui.categoryId).change();
      $(this.ui.cargoType).change();
      $(this.ui.jsDatepicker).change();
      this.validationForTTA();
    };

    validationForTTA() {
      const operation = this;
      var lockType = $(operation.ui.lockType).val();
      var max = (lockType == 'panamax') ? 5000 : 16285;
      $(operation.ui.teuCapacity).rules('remove', 'max');

      $(operation.ui.teuCapacity).rules('add', {
        max: max,
        messages: {
          max: jQuery.validator.format("Exceeding maximum TEU capacity: {0}")
        }
      });

      if ($(operation.ui.teuCapacity).val() != "") {
        $(operation.ui.teuCapacity).valid();
      };
    };

    needFormValidationCustom(options) {
      const operation = this;

      operation.needFormValidation(options)

      $(operation.ui.teuCapacity).rules('add', {
        greaterThanEqual: [operation.ui.fullTeusCarried, 'sum of TTL and Empty TTE', operation.ui.teuEmpty]
      });

      $(operation.ui.idLength).rules('add', {
        max: 370,
        messages: {
          max: jQuery.validator.format("Exceeding maximum Length: {0}")
        }
      })

      $(operation.ui.beam).rules('add', {
        max: 51.25,
        messages: {
          max: jQuery.validator.format("Exceeding maximum Beam: {0}")
        }
      })

      $(operation.ui.draft).rules('add', {
        max: 15.24,
        messages: {
          max: jQuery.validator.format("Exceeding maximum Draft: {0}")
        }
      })
    };

    vesselCategorySelect() {
      var operation = this;
      $(operation.ui.categoryId).on('change', function () {
        var year = moment($(operation.ui.jsDatepicker).val(), "DD MMM YYYY").year();
        var categoryId = $(operation.ui.categoryId).find("option:selected").val();

        operation.compareText(categoryId, year);
        operation.handleFormInputs();
      });
    };

    setLockType() {
      var operation = this;
      var length, beam, draft, lock_type;

      $(operation.ui.idLength).on('keyup', function () {
        beam = $(operation.ui.beam).val() || 0;
        draft = $(operation.ui.draft).val() || 0;

        lock_type = findLockType($(this).val(), beam, draft);
        $(operation.ui.lockType).val(lock_type);
        operation.validationForTTA();
      });

      $(operation.ui.beam).on('keyup', function () {
        length = $(operation.ui.idLength).val() || 0;
        draft = $(operation.ui.draft).val() || 0;

        lock_type = findLockType(length, $(this).val(), draft);
        $(operation.ui.lockType).val(lock_type);
        operation.validationForTTA();
      });

      $(operation.ui.draft).on('keyup', function () {
        length = $(operation.ui.idLength).val() || 0;
        beam = $(operation.ui.beam).val() || 0;

        lock_type = findLockType(length, beam, $(this).val());
        $(operation.ui.lockType).val(lock_type);
        operation.validationForTTA();
      });
    };

    cargoTypeHandler() {
      var operation = this;

      $(operation.ui.cargoType).on('change', function () {
        var categorySelected = $(operation.ui.categoryId).find("option:selected").val();

        if (categorySelected == 3) {
          if ($(this).filter(":checked").val() == "laden") {
            $(operation.ui.cargoWeight).addClass("required");
          } else {
            $(operation.ui.cargoWeight).removeClass("required is-invalid");
          }
        }

        if ($(this).filter(":checked").val() == "laden" || $(this).filter(":checked").val() === undefined) {
          $(operation.ui.fullTeusCarried).removeAttr('disabled');
        } else {
          $(operation.ui.fullTeusCarried).val(0);
          $(operation.ui.fullTeusCarried).attr("disabled", "disabled");
        }
      });
    };

    loadSkipAcpVisibility() {
      var operation = this;
      $(operation.ui.chkSkipAcpVisibility).on('click', (e) => {
        if ($(operation.ui.jsFormValidation).valid()) {
          $(operation.ui.skipAcpVisibility).val($(operation.ui.chkSkipAcpVisibility).is(':checked'));
          $(operation.ui.openOptional).val("true");
          $(operation.ui.resultSkipAcpVisibility).html('<i class="fas fa-spinner fa-pulse text-dark-blue"></i> Processing...')
        } else {
          e.preventDefault();
        }
        $(operation.ui.btnSubmit).click();
      });
    };

    loadSkipTransitReservation() {
      var operation = this;
      $(operation.ui.chkSkipTransitReservation).on('click', (e) => {
        if ($(operation.ui.jsFormValidation).valid()) {
          $(operation.ui.skipTransitReservation).val($(operation.ui.chkSkipTransitReservation).is(':checked'));
          $(operation.ui.openOptional).val("true");
          $(operation.ui.resultSkipTransitReservation).html('<i class="fas fa-spinner fa-pulse text-dark-blue"></i> Processing...')
        } else {
          e.preventDefault();
        }
        $(operation.ui.btnSubmit).click();
      });
    };

    loadSkipDaylightTransit() {
      var operation = this;
      $(operation.ui.chkSkipDaylightTransit).on('click', (e) => {
        if ($(operation.ui.jsFormValidation).valid()) {
          $(operation.ui.skipDaylightTransit).val($(operation.ui.chkSkipDaylightTransit).is(':checked'));
          $(operation.ui.openOptional).val("true");
          $(operation.ui.resultSkipDaylightTransit).html('<i class="fas fa-spinner fa-pulse text-dark-blue"></i> Processing...')
        } else {
          e.preventDefault();
        }
        $(operation.ui.btnSubmit).click();
      });
    };

    loadSkipFirstTransit() {
      var operation = this;
      $(operation.ui.chkSkipFirstTransit).on('click', (e) => {
        if ($(operation.ui.jsFormValidation).valid()) {
          $(operation.ui.skipFirstTransit).val($(operation.ui.chkSkipFirstTransit).is(':checked'));
          $(operation.ui.openOptional).val("true");
          $(operation.ui.resultSkipFirstTransit).html('<i class="fas fa-spinner fa-pulse text-dark-blue"></i> Processing...')
        } else {
          e.preventDefault();
        }
        $(operation.ui.btnSubmit).click();
      });
    };

    quickCalculation() {
      const operation = this;
      $(operation.ui.imo).on('blur', function () {
        let imo = $(this).val();
        operation.findIMO(imo);
      });

      $(operation.ui.name).on('blur', function () {
        let name = $(this).val();
        $(operation.ui.jsVesselName).text(name);
      });
    };

    findIMO(imo) {
      $.ajax({
        method: "GET",
        url: "/find_by_imo",
        data: { imo: imo }
      }).done(function (res) {
        console.log("Done");
      }).fail(function () {
        console.log("error");
      });
    };

    findMarineTraffic(imo) {
      const operation = this;
      let url = "https://services.marinetraffic.com/api/vesselmasterdata/v:3/54da5bc56cee56fa20147e3c167f77a8261352e0/protocol:jsono/imo:" + imo;

      $(operation.ui.jsVesselImo).text(imo);

      $.ajax({
        method: "GET",
        url: url,
      }).done(function (res) {
        console.log("Done");
        if (res.DATA) {
          var vessel_info = res.DATA[0];

          $(operation.ui.name).val(vessel_info['NAME']);
          $(operation.ui.jsVesselImo).text(vessel_info['IMO']);
          $(operation.ui.jsVesselName).text(vessel_info['NAME']);
          $(operation.ui.idLength).val(vessel_info['LENGTH_OVERALL']);
          $(operation.ui.beam).val(vessel_info['BREADTH_EXTREME']);
          $(operation.ui.draft).val(vessel_info['DRAUGHT']);
          $(operation.ui.oil_capacity).val(vessel_info['LIQUID_OIL']);
          $(operation.ui.displacement).val(vessel_info['DISPLACEMENT_SUMMER']);

        };
      }).fail(function () {
        console.log("error");
      });
    };

    changeDatePicker() {
      const operation = this;
      $(operation.ui.jsDatepicker).on('change', function (evt, date) {
        var year = moment($(this).val(), "DD MMM YYYY").year();
        var categoryId = $(operation.ui.categoryId).find("option:selected").val();

        operation.compareText(categoryId, year);
        operation.handleFormInputs();
      });
    };

    compareText(categoryId, year) {
      const operation = this;

      if (year >= 2023 || isNaN(year)) {
        $(operation.ui.cat12).addClass('d-block').removeClass('d-none');
        $(operation.ui.cat13).addClass('d-block').removeClass('d-none');
        $(operation.ui.cat14).addClass('d-block').removeClass('d-none');
        $(operation.ui.jsShowCompare).html(I18n.t('compare.old_structure'));
      } else {
        if (categoryId == 12 || categoryId == 13 || categoryId == 14) {
          $(operation.ui.categoryId).val(null).trigger('change');
        }
        $(operation.ui.cat12).removeClass('d-block').addClass('d-none');
        $(operation.ui.cat13).removeClass('d-block').addClass('d-none');
        $(operation.ui.cat14).removeClass('d-block').addClass('d-none');
        $(operation.ui.jsShowCompare).html(I18n.t('compare.new_structure'));
      };

      if (categoryId == 8) {
        if (year >= 2024 || isNaN(year)) {
          $(operation.ui.jsShowCompare).html(I18n.t('compare.passenger_vessels_old_structure'));
        } else if (year == 2022) {
          $(operation.ui.jsShowCompare).html(I18n.t('compare.new_structure'));
        } else {
          $(operation.ui.jsShowCompare).html(I18n.t('compare.passenger_vessels_new_structure'));
        }
      }
    };

    handleShowCompare() {
      const operation = this;

      $(operation.ui.showCompare).on('change', function () {
        operation.handleFormInputs();
      });
    }

    handleFormInputs() {
      const operation = this;
      var divInputs = $(operation.ui.jsCustomInputs + " div.col-12");
      var categorySelected = $(operation.ui.categoryId).find('option:selected').val() || $(operation.ui.categoryId).val();
      var yearSelected = moment($(operation.ui.jsDatepicker).val(), "DD MMM YYYY").year();
      var compareSelected = $(operation.ui.showCompare).is(":checked");
      var lockType = findLockType($(operation.ui.idLength).val(), $(operation.ui.beam).val(), $(operation.ui.draft).val());

      $(operation.ui.lockType).val(lockType);
      $(operation.ui.jsInputsBox).removeClass("d-none");

      divInputs.each(function (idx, element) {
        $(element).removeClass("d-block").find(":input").removeClass("required");
        $(element).addClass("d-none");
      });

      if (yearSelected >= 2023 || isNaN(yearSelected)) {
        if (categorySelected == 1 || categorySelected == 9) {
          $(operation.ui.jsPcums).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsTeuDry).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsTeuReefer).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsTeuEmpty).addClass('d-block').find(":input").addClass("required");

          if (compareSelected) {
            $(operation.ui.jsFullTeus).addClass('d-block').find(":input").addClass("required");
          }
        } else if (categorySelected == 11) {
          $(operation.ui.jsPcums).addClass('d-block').find(":input").addClass("required");
          if (compareSelected) {
            $(operation.ui.jsFullTeus).addClass('d-block').find(":input").addClass("required");
          }
        } else if (categorySelected == 2) {
          $(operation.ui.jsTeuCapacity).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsFullTeus).addClass('d-block').find(":input").addClass("required");
          // $(operation.ui.jsNewLoyaltyProgram).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsTeuEmpty).addClass('d-block').find(":input").addClass("required");
          //if (compareSelected) {
          $(operation.ui.jsLoyaltyProgram).addClass('d-block').find(":input").addClass("required");
          //}
        } else if (categorySelected == 3) {
          $(operation.ui.jsVesselDwt).addClass("d-block").find(":input").addClass("required");
          if (compareSelected) {
            $(operation.ui.jsDryBulk).addClass("d-block").find(":input").addClass("required");
            if (lockType == "neopanamax") {
              $(operation.ui.jsCargoWeight).addClass("d-block");
              if ($(operation.ui.cargoType + ":checked").val() == "laden") {
                $(operation.ui.jsCargoWeight).find(":input").addClass("required");
              }
            }
          }
        } else if (categorySelected == 4) {
          $(operation.ui.jsPcums).addClass("d-block").find(":input").addClass("required");
          if (compareSelected && lockType == "neopanamax") {
            $(operation.ui.jsCargoWeight).addClass("d-block").find(":input").addClass("required");
          }
        } else if (categorySelected == 5) {
          $(operation.ui.jsPcums).addClass("d-block").find(":input").addClass("required");
        } else if (categorySelected == 6 || categorySelected == 7) {
          $(operation.ui.jsNewCargoWeight).addClass("d-block").find(":input").addClass("required");
          if (compareSelected) {
            $(operation.ui.jsCargoCapacity).addClass("d-block").find(":input").addClass("required");
            if (lockType == "neopanamax") {
              $(operation.ui.jsCargoWeight).addClass("d-block").find(":input").addClass("required");
            }
          }
          //} else if (categorySelected == 8) {
          //  $(operation.ui.jsPcums).addClass("d-block").find(":input").addClass("required");
          //  if (compareSelected) {
          //    $(operation.ui.jsGrt).addClass("d-block").find(":input").addClass("required");
          //    $(operation.ui.jsBerthCapacity).addClass("d-block").find(":input").addClass("required");
          //    $(operation.ui.jsPaxCapacity).addClass("d-block").find(":input").addClass("required");
          //    $(operation.ui.jsDaylightTransit).addClass("d-block").find(":input").addClass("required");
          //  }
        } else if (categorySelected == 10) {
          $(operation.ui.jsPcums).addClass("d-block").find(":input").addClass("required");
          if (compareSelected) {
            $(operation.ui.jsCargoWeight).addClass("d-block").find(":input").addClass("required");
            $(operation.ui.jsVesselDwt).addClass("d-block").find(":input").addClass("required");
          }
        } else {
          $(operation.ui.jsInputsBox).addClass("d-none");
        };

      } else {
        //Change Values
        //$(operation.ui.teuEmpty).val("0");

        if (categorySelected == 1 || categorySelected == 9) {
          $(operation.ui.jsPcums).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsFullTeus).addClass('d-block').find(":input").addClass("required");

          if (compareSelected) {
            $(operation.ui.jsTeuDry).addClass('d-block').find(":input").addClass("required");
            $(operation.ui.jsTeuReefer).addClass('d-block').find(":input").addClass("required");
            $(operation.ui.jsTeuEmpty).addClass('d-block').find(":input").addClass("required");
          }
        } else if (categorySelected == 11) {
          $(operation.ui.jsPcums).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsFullTeus).addClass('d-block').find(":input").addClass("required");
        } else if (categorySelected == 2) {
          $(operation.ui.jsTeuCapacity).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsFullTeus).addClass('d-block').find(":input").addClass("required");
          // $(operation.ui.jsAcpVisibility).addClass('d-block').find(":input").addClass("required");
          $(operation.ui.jsLoyaltyProgram).addClass('d-block').find(":input").addClass("required");
          if (compareSelected) {
            // $(operation.ui.jsNewLoyaltyProgram).addClass('d-block').find(":input").addClass("required");
            $(operation.ui.jsTeuEmpty).addClass('d-block').find(":input").addClass("required");
          }
        } else if (categorySelected == 3) {
          $(operation.ui.jsVesselDwt).addClass("d-block").find(":input").addClass("required");
          $(operation.ui.jsDryBulk).addClass("d-block").find(":input").addClass("required");
          if (lockType == "neopanamax") {
            $(operation.ui.jsCargoWeight).addClass("d-block").find(":input").addClass("required");
          }
          if ($(operation.ui.cargoType + ":checked").val() == "laden") {
            $(operation.ui.jsCargoWeight).find(":input").addClass("required");
          }
        } else if (categorySelected == 4) {
          $(operation.ui.jsPcums).addClass("d-block").find(":input").addClass("required");
          if (lockType == "neopanamax") {
            $(operation.ui.jsCargoWeight).addClass("d-block").find(":input").addClass("required");
          }
        } else if (categorySelected == 5) {
          $(operation.ui.jsPcums).addClass("d-block").find(":input").addClass("required");
        } else if (categorySelected == 6 || categorySelected == 7) {
          $(operation.ui.jsCargoCapacity).addClass("d-block").find(":input").addClass("required");
          if (lockType == "neopanamax") {
            $(operation.ui.jsCargoWeight).addClass("d-block").find(":input").addClass("required");
          }
          if (compareSelected) {
            $(operation.ui.jsNewCargoWeight).addClass("d-block").find(":input").addClass("required");
          }
          //} else if (categorySelected == 8) {
          //  $(operation.ui.jsPcums).addClass("d-block").find(":input").addClass("required");
          //  $(operation.ui.jsGrt).addClass("d-block").find(":input").addClass("required");
          //  $(operation.ui.jsBerthCapacity).addClass("d-block").find(":input").addClass("required");
          //  $(operation.ui.jsPaxCapacity).addClass("d-block").find(":input").addClass("required");
          //  $(operation.ui.jsDaylightTransit).addClass("d-block").find(":input").addClass("required");
        } else if (categorySelected == 10) {
          $(operation.ui.jsPcums).addClass("d-block").find(":input").addClass("required");
          $(operation.ui.jsCargoWeight).addClass("d-block").find(":input").addClass("required");
          $(operation.ui.jsVesselDwt).addClass("d-block").find(":input").addClass("required");
        } else {
          $(operation.ui.jsInputsBox).addClass("d-none");
        };
      };

      // Passenger Vessels
      if (categorySelected == 8) {
        $(operation.ui.jsInputsBox).removeClass("d-none");
        if (yearSelected >= 2024 || isNaN(yearSelected)) {
          $(operation.ui.jsPcums).addClass("d-block").find(":input").addClass("required");
          if (compareSelected) {
            $(operation.ui.jsGrt).addClass("d-block").find(":input").addClass("required");
            $(operation.ui.jsBerthCapacity).addClass("d-block").find(":input").addClass("required");
            $(operation.ui.jsPaxCapacity).addClass("d-block").find(":input").addClass("required");
            $(operation.ui.jsDaylightTransit).addClass("d-block").find(":input").addClass("required");
          }
        } else {
          $(operation.ui.jsPcums).addClass("d-block").find(":input").addClass("required");
          $(operation.ui.jsGrt).addClass("d-block").find(":input").addClass("required");
          $(operation.ui.jsBerthCapacity).addClass("d-block").find(":input").addClass("required");
          $(operation.ui.jsPaxCapacity).addClass("d-block").find(":input").addClass("required");
          $(operation.ui.jsDaylightTransit).addClass("d-block").find(":input").addClass("required");
        }
      };
    };
  };

  var findLockType = function (length, beam, draft) {
    const LENGTH = 294.44;
    const BEAM = 32.61;
    const DRAFT = 12.04;

    if (length > LENGTH || beam > BEAM) {
      return "neopanamax"
    } else {
      return "panamax"
    }
  };

  window.Calculator = Calculator;
})();
