/**
* Number.prototype.format(n, s, c)
*
* @param integer precision: length of decimal
* @param mixed   delimiter: sections delimiter
* @param mixed   separator: decimal delimiter
*/

Number.prototype.format = function (precision = 2, delimiter = ',', separator = '.') {
  var regex = '\\d(?=(\\d{' + 3 + '})+' + (precision > 0 ? '\\D' : '$') + ')',
    num = this.toFixed(Math.max(0, ~~precision));

  return (separator ? num.replace('.', separator) : num).replace(new RegExp(regex, 'g'), '$&' + (delimiter || ','));
};

(function () {
  class Common {
    constructor() {
      this.ui = {};
      this.ui.jsOnlyNumber = ".js-only-number";
      this.ui.jsDatepicker = ".js-datepicker";
      this.ui.jsFormValidation = ".js-need-validation";
      this.ui.jsIntNumber = ".js-int-number";
      this.ui.jsFloatNumber = ".js-float-number";
      this.ui.togglePassword = ".toggle-password";
    };

    loadComponents() {
      this.loadTooltips();
      this.hideLoadTooltips();
      this.loadDatepicker();
      this.loadSelect2();
    };

    onlyNumber() {
      $(this.ui.jsOnlyNumber).on('keydown', (evt) => {
        const { value, maxLength } = evt.target;

        var keys = [
          8, // backspace
          9, // tab
          13, // enter
          17, // control
          46, // delete
          37, 39, 38, 40, // arrow keys<^>v
          67, // c
          86, // v
          110, // dot
          190 // dot
        ]

        var charCode = (evt.which) ? evt.which : evt.keyCode;

        if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105) && $.inArray(event.which, keys) == -1) {
          return false;
        }

        if (evt.target.hasAttribute('maxlength')) {
          if (String(value).length >= maxLength && $.inArray(event.which, keys) == -1) {
            evt.preventDefault();
            return false;
          }
        }

        return true;
      });
    };

    loadDatepicker(options) {
      var options = options || {};
      var start_date_from = options.start_date_from;

      $(this.ui.jsDatepicker).bootstrapMaterialDatePicker({
        format: 'DD MMM YYYY',
        clearButton: true,
        weekStart: 1,
        time: false,
        switchOnClick: true
      });

      if (typeof start_date_from != 'undefined') {
        $(this.ui.jsDatepicker).bootstrapMaterialDatePicker('setMinDate', start_date_from);
      }
    };

    loadDatePickerFromTo(from, to) {
      $(from).on('change', function (e, date) {
        $(to).bootstrapMaterialDatePicker('setMinDate', date);
      });

      $(to).on('change', function (e, date) {
        $(from).bootstrapMaterialDatePicker('setMaxDate', date);
      });
    };

    setMinMaxDatePickerFromTo(from, to) {
      var date_from = $(from).val();
      var date_to = $(to).val();

      $(from).bootstrapMaterialDatePicker('setMaxDate', date_to);
      $(to).bootstrapMaterialDatePicker('setMinDate', date_from);
    };

    needFormValidation(options) {
      var options = options || {};

      $.extend($.validator.defaults, {
        errorElement: 'span',
        errorClass: 'invalid-feedback',
        success: function (label, element) {
          label.parent().removeClass('error');
          label.remove();
        },
        errorPlacement: function (error, element) {
          if (element.parent('.input-group').length || element.prop('type') === 'checkbox' || element.prop('type') === 'radio') {
            error.insertAfter(element.parent());
          } else if ((element.prop('type') === 'select-multiple' && element.hasClass("select2")) || $(element).hasClass("select2")) {
            error.insertAfter(element.closest("div").find("span.select2"));
          } else {
            error.insertAfter(element);
          }
        },
        highlight: function (element, errorClass, validClass) {
          if (($(element).prop('type') === 'select-multiple' && $(element).hasClass("select2")) || $(element).hasClass("select2")) {
            $(element).closest("div").find("span.select2-selection").addClass("is-invalid");
          } else {
            $(element).addClass("is-invalid");
          }
        },
        unhighlight: function (element, errorClass, validClass) {
          if (($(element).prop('type') === 'select-multiple' && $(element).hasClass("select2")) || $(element).hasClass("select2")) {
            $(element).closest("div").find("span.select2-selection").removeClass("is-invalid");
          } else {
            $(element).removeClass("is-invalid");
          }
        }
      });

      $.validator.addMethod("greaterThanEqual", function (value, element, params) {
        var target = $(params[0]);
        var target2 = $(params[2]);

        if (this.settings.onfocusout && target.not(".validate-greaterThanEqual-blur").length) {
          target.addClass("validate-greaterThanEqual-blur").on("blur.validate-greaterThanEqual", function () {
            $(element).valid();
          });
        }

        total = Number(target.val().replaceAll(',', ''));

        if (target2.length) {
          if (this.settings.onfocusout && target2.not(".validate-greaterThanEqual-blur").length) {
            target2.addClass("validate-greaterThanEqual-blur").on("blur.validate-greaterThanEqual", function () {
              $(element).valid();
            });
          }

          total = total + Number(target2.val().replaceAll(',', ''));
        }
        return Number(value.replaceAll(',', '')) >= total;
      }, 'Must be greater than {1}.');

      $.validator.addMethod("max", function (value, element, param) {
        return this.optional(element) || Number(value.replaceAll(',', '')) <= param;
      }, 'Please enter a value less than or equal to {0}.');

      $('.js-need-validation').validate(options);
    };

    loadSelect2() {
      $('.select2').select2();
    };

    loadTooltips() {
      this.hideLoadTooltips();

      $('[data-toggle="tooltip"]').tooltip({
        html: true
      });
    };

    hideLoadTooltips() {
      $('body').on('click', function (e) {
        $('[data-toggle="tooltip"]').each(function () {
          if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.tooltip').has(e.target).length === 0) {
            $(this).tooltip('hide');
          }
        });
      })

      $('[data-toggle="tooltip"]').tooltip('hide');
    };

    hideAjaxIndicator() {
      $('.js-ajax-indicator').removeClass("d-block").addClass("d-none");
    };

    pageLoaderIn() {
      $('.page-loader-wrapper').fadeIn();
    };

    pageLoaderOut() {
      $('.page-loader-wrapper').fadeOut();
    };

    formatNumberToCurrency() {
      const operation = this;
      // $('[type="number"]').each(function () {
      //   var number = parseFloat($(this).val()).format();
      //   $(this).val(number);
      // });

      $(operation.ui.jsFloatNumber).each(function () {
        var number = parseFloat($(this).val()).format();
        if (isNaN(number)) {
          $(this).val(0);
        }
      });

      $(operation.ui.jsIntNumber).each(function () {
        var number = parseInt($(this).val()).format();
        if (isNaN(number)) {
          $(this).val(0);
        }
      });
    };

    maskedNumber() {
      const operation = this;

      $(operation.ui.jsFloatNumber).each(function () {
        $(this).inputmask({
          alias: 'numeric',
          groupSeparator: ',',
          digits: 2,
          digitsOptional: false,
          allowMinus: false
        });
      });

      $(operation.ui.jsIntNumber).each(function () {
        $(this).inputmask({
          alias: 'numeric',
          groupSeparator: ',',
          digits: 0,
          digitsOptional: false,
          allowMinus: false
        });
      });
    };

    togglePassword() {
      const operation = this;

      $(operation.ui.togglePassword).on('click', function () {
        let icon = $(this);
        let input = icon.closest('.input-group').find("input");

        if (input.attr('type') === 'password') {
          input.attr('type', 'text');
          icon.removeClass("fa-eye-slash").addClass("fa-eye")
        } else {
          input.attr('type', 'password');
          icon.removeClass("fa-eye").addClass("fa-eye-slash")
        }
      });
    };
  };

  window.Common = Common;
})();
