import I18n from 'i18n-js';
I18n.translations || (I18n.translations = {});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
  "activerecord": {
    "attributes": {
      "advisory": {
        "advisory_type": "Advisory type",
        "draft_limit": "Draft limit",
        "expiration_date": "Expiration date",
        "is_active": "Active",
        "link": "Link",
        "lock_type": "Lock type",
        "severity": "Severity",
        "short_description": "Short description",
        "start_date": "Start date",
        "vessel_id": "Vessel category"
      },
      "calculator": {
        "beam": "Extreme Beam",
        "berth_capacity": "Berth Capacity",
        "bow_thruster": "Bow Thruster Operative",
        "cargo_capacity": "Cargo Capacity",
        "cargo_type": "Vessel with Cargo",
        "cargo_weight": "Cargo Weight",
        "cargo_weight_mc": "Cargo Weight",
        "category_id": "Vessel Category",
        "created_at": "Calculation Date",
        "daylight_transit": "Daylight Transit Request",
        "displacement": "Displacement",
        "dry_bulk_type": "Commodity",
        "eta": "ETA (Estimated Time of Arrival)",
        "exceeds_acp_visibility": "Exceeding ACP Visibility",
        "first_transit": "1st Panama Canal Transit",
        "full_teus_carried": "TTL - Total TEU with cargo",
        "grt": "GRT",
        "imo": "IMO",
        "length": "Length Overall (LOA)",
        "loyalty_program": "Loyalty Program - TTA Carried",
        "name": "Vessel Name",
        "new_loyalty_program": "New Loyalty Program - TTA Carried",
        "oil_capacity": "Oil Capacity",
        "pax_capacity": "Pax Capacity (PAX-ITC)",
        "pcums": "PCUMS",
        "show_compare": "Do you want to compare with 2022?",
        "teu_capacity": "TTA - Total TEU capacity",
        "teu_dry": "TTD - TEU Dry",
        "teu_empty": "TTE - Total empty TEU",
        "teu_reefer": "TTR - TEU Reefer",
        "tfw_draft": "TFW Draft",
        "transit_direction": "Transit Direction",
        "transit_type": "Type of Transit",
        "vessel_dwt": "Vessel DWT",
        "vessel_type": "Vessel Type"
      },
      "category": {
        "name": "Category Name"
      },
      "extra_charge": {
        "concept": "Additional charge description",
        "price": "Cost"
      },
      "tariff": {
        "beam_from": "Beam from",
        "beam_to": "Beam to",
        "capacity_from": "Capacity from",
        "capacity_to": "Capacity to",
        "cargo_type": "Cargo",
        "category_id": "Category",
        "code": "Code",
        "date_from": "Date from",
        "date_to": "Date to",
        "displacement_from": "Displacement from",
        "displacement_to": "Displacement to",
        "dry_bulk_type": "Dry Bulk",
        "filter_type": "Filter",
        "length_from": "Length from",
        "length_to": "Length to",
        "lock_type": "Lock Type",
        "other_filter": "Filter",
        "other_from": "Other from",
        "other_to": "Other to",
        "price": "Price",
        "vessel_type": "Vessel Type"
      },
      "tariff/acp_visibility": {
        "other_from": "Length in Laden condition",
        "other_to": "Length in Ballast condition"
      },
      "tariff/handling_line": {
        "other_from": "Draft from",
        "other_to": "Draft to"
      },
      "tariff/locomotive_wire": {
        "other_from": "Draft from",
        "other_to": "Draft to",
        "price": "Wire Quantity"
      },
      "tariff/toll": {
        "other_from": "Utilization percentage from",
        "other_to": "Utilization percentage to"
      },
      "tariff/tug": {
        "filter_type": "Type of Transit"
      },
      "vessel": {
        "beam": "Extreme Beam",
        "bow_thruster": "Bow Thruster Operative",
        "cargo_type": "Vessel with Cargo",
        "category_id": "Vessel Category",
        "displacement": "Displacement",
        "exceeds_acp_visibility": "Exceeding ACP Visibility",
        "first_transit": "1st Panama Canal Transit",
        "grt": "GRT",
        "imo": "IMO",
        "length": "Length Overall (LOA)",
        "lock_type": "Lock Type",
        "name": "Vessel Name",
        "oil_capacity": "Oil Capacity",
        "pcums": "PCUMS",
        "short_name": "Short Name",
        "sin": "SIN number",
        "tfw_draft": "TFW Draft",
        "vessel_type": "Vessel Type"
      },
      "water_surcharge": {
        "max_neopanamax_draft_ft": "Max Neopanamax draft ft",
        "max_panamax_draft_ft": "Max Panamax draft ft",
        "projected_date": "Projected Date",
        "projected_gatun_water_level": "Projected Gatun water level",
        "surcharge_pcent": "Surcharge pcent"
      }
    },
    "enums": {
      "advisory": {
        "advisory_types": {
          "draft": "Draft",
          "other": "Other",
          "outage": "Outage"
        },
        "lock_types": {
          "all_advisory": "All",
          "neopanamax": "Neopanamax",
          "regular": "Regular"
        },
        "severities": {
          "high": "High",
          "low": "Low",
          "medium": "Medium"
        }
      },
      "calculator": {
        "bow_thrusters": {
          "none_operative": "Not Operative",
          "operative": "Operative"
        },
        "cargo_types": {
          "ballast": "Ballast",
          "laden": "Laden"
        },
        "dry_bulk_types": {
          "coal": "Coal",
          "grain": "Grain",
          "iron_ore": "Iron Ore",
          "other": "Others (ODB)"
        },
        "lock_types": {
          "any_lock": "Any lock",
          "neopanamax": "Neopanamax",
          "panamax": "Panamax",
          "regular": "Regular"
        },
        "loyalty_programs": {
          "cat1": "Between 1,500,000 and 2,000,000 teus",
          "cat2": "Between 1,000,000 and 1,499,999 teus",
          "cat3": "Between 450,001 and 999,999 teus",
          "cat4": "Less than 450,000 teus",
          "cat_1a": "Between 2,000,001 and 3,000,000 teus",
          "cat_plus": "Above 3,000,000 teus"
        },
        "new_loyalty_programs": {
          "clp": "With Loyalty Program 2023-2024",
          "slp": "Without Loyalty Program"
        },
        "transit_directions": {
          "nb": "Northbound",
          "sb": "Southbound"
        },
        "transit_types": {
          "full_transit": "Full Transit",
          "partial_transit": "Partial Transit"
        },
        "vessel_types": {
          "neo": "Neopanamax",
          "panamax_plus": "Panamax Plus",
          "regular": "Regular",
          "super_vessel": "Super Vessel",
          "without_type": "Without Type"
        }
      },
      "tariff": {
        "cargo_types": {
          "ballast": "Ballast",
          "laden": "Laden"
        },
        "dry_bulk_types": {
          "coal": "Coal",
          "grain": "Grain",
          "iron_ore": "Iron Ore",
          "other": "Others (ODB)"
        },
        "filter_types": {
          "cp_suab": "PCUMS",
          "dwt": "DWT",
          "full_transit": "Full Transit",
          "mt_transported": "MT transported",
          "no_filter": "No Filter",
          "partial_transit": "Partial Transit",
          "per_berth": "Per berth",
          "round_trip": "Round trip",
          "teu_allowed": "TEU allowed",
          "teu_onboard": "TEU onboard"
        },
        "lock_types": {
          "any_lock": "Any lock",
          "neopanamax": "Neopanamax",
          "panamax": "Panamax",
          "regular": "Regular"
        },
        "other_filters": {
          "and_condition": "And Condition",
          "nb_none_operative": "Northbound Transit - Not Operative Bow Thruster",
          "nb_operative": "Northbound Transit - Operative Bow Thruster",
          "none_filter": "No Filter",
          "or_condition": "Or Condition",
          "sb": "Southbound Transit",
          "tankbulk": "Tankers or Bulk Carriers"
        },
        "vessel_types": {
          "neo": "Neopanamax",
          "panamax_plus": "Panamax Plus",
          "regular": "Regular",
          "super_vessel": "Super Vessel",
          "without_type": "Without Type"
        }
      },
      "tariff/visit_creation": {
        "vessel_types": {
          "neo": "Neopanamax",
          "regular": "Regular",
          "super_vessel": "Super Vessel, Panamax Plus"
        }
      },
      "vessel": {
        "bow_thrusters": {
          "none_operative": "No",
          "operative": "Yes"
        },
        "statuses": {
          "approved": "Approved",
          "pending": "Pending"
        }
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      },
      "models": {
        "calculator": {
          "must_be_greater_than_full_teus_carried": "must be greater than Full TEUS Carried"
        },
        "tariff": {
          "attributes": {
            "beam_to": {
              "must_be_greater_than_beam_from": "must be greater than Beam from"
            },
            "capacity_to": {
              "must_be_greater_than_capacity_from": "must be greater than Capacity from"
            },
            "date_to": {
              "must_be_greater_than_date_from": "must be greater than Date from"
            },
            "displacement_to": {
              "must_be_greater_than_displacement_from": "must be greater than Displacement from"
            },
            "length_to": {
              "must_be_greater_than_length_from": "must be greater than Lenght from"
            },
            "other_to": {
              "must_be_greater_than_other_from": "must be greater than Other from"
            }
          }
        },
        "tariff/toll": {
          "attributes": {
            "filter_type": {
              "msg_for_cat2": "Not valid. Allowed values: TEU allowed or TEU onboard",
              "msg_for_cat3": "Not valid. Allowed values: DWT or MT transported",
              "msg_for_cat4": "Not valid. Allowed values: No filter or MT transported or PCUMS",
              "msg_for_cat7": "Not valid. Allowed values: No filter or Round trip",
              "msg_for_cat8": "Not valid. Allowed values: No filter or Per beth"
            },
            "lock_type": {
              "msg_for_cat2": "Not valid. Allowed values: Neopanamax or Panamax",
              "msg_for_cat3": "Not valid"
            },
            "vessel_type": {
              "not_valid": "Not valid. Allowed values: Neopanamax, Super Vessel or Regular"
            }
          }
        }
      }
    },
    "help": null,
    "models": {
      "advisory": {
        "one": "Advisory",
        "other": "Advisories Panel"
      },
      "calculator": {
        "one": "Calculation",
        "other": "Calculations"
      },
      "email_request": {
        "one": "Email Request",
        "other": "Email Requests"
      },
      "tariff": {
        "one": "Tariff",
        "other": "Tariffs"
      },
      "tariff/acp_visibility": {
        "one": "Exceeding Visibility Charge",
        "other": "Exceeding Visibility Charges"
      },
      "tariff/admeasurement": {
        "one": "Admeasurement Service Charge",
        "other": "Admeasurement Service Charges"
      },
      "tariff/daylight_transit": {
        "one": "Daylight Transits",
        "other": "Daylight Transits"
      },
      "tariff/fresh_water_surcharge": {
        "one": "Fresh Water Surcharge",
        "other": "Fresh Water Surcharges"
      },
      "tariff/handling_line": {
        "one": "Handling Line",
        "other": "Handling Lines"
      },
      "tariff/locomotive": {
        "one": "Locomotive Charge",
        "other": "Locomotive Charges"
      },
      "tariff/locomotive_wire": {
        "one": "Locomotive Wire",
        "other": "Locomotive Wires"
      },
      "tariff/pcsopep": {
        "one": "PCSOPEP",
        "other": "PCSOPEP"
      },
      "tariff/security_charge": {
        "one": "Security Charge",
        "other": "Security Charges"
      },
      "tariff/teus_on_deck": {
        "one": "TEUS on Deck",
        "other": "TEUS on Decks"
      },
      "tariff/toll": {
        "one": "Toll",
        "other": "Tolls"
      },
      "tariff/transit_reservation": {
        "one": "Transit Reservation",
        "other": "Transit Reservations"
      },
      "tariff/tug": {
        "one": "Tug Service",
        "other": "Tug Services"
      },
      "tariff/vessel_inspection": {
        "one": "Vessel Inspection Service",
        "other": "Vessel Inspection Services"
      },
      "tariff/visit_creation": {
        "one": "Transit Itinerary Creation Fee",
        "other": "Transit Itinerary Creation Fees"
      },
      "user": {
        "one": "User",
        "other": "Users"
      },
      "vessel": {
        "one": "Vessel",
        "other": "Vessels"
      },
      "water_surcharge": {
        "one": "Water Surcharge",
        "other": "Water Surcharges"
      }
    }
  },
  "advisory": {
    "title": "Advisories Panel"
  },
  "buttons": {
    "add_favorite": "Add to favorite",
    "back": "Back",
    "calculate": "Calculate",
    "calculate_create": "Calculate and Save Vessel",
    "clear": "Clear",
    "close": "Close",
    "compare_pdf": "Compare",
    "dashboard": "Dashboard",
    "destroy": "Destroy",
    "edit": "Edit",
    "email_request_html": "Finding any issues? Contact us <i>here</i>",
    "filters": "Filters",
    "marine_traffic": "Search in Marine Traffic",
    "my_vessels": "My Vessels",
    "preview_pdf": "Proforma",
    "remove_favorite": "Remove to favorite",
    "search": "Search",
    "select": "Select",
    "send_pdf": "Send me a copy",
    "submit_contact": "Submit",
    "update_profile": "Update Profile",
    "view": "View",
    "view_calculate": "View Calculation",
    "view_more": "View more",
    "want_create_vessel": "Calculate"
  },
  "calculator": {
    "acp_visibility": "Exceed ACP Visibility",
    "advance_charges": "Advance Charges",
    "category": "Category",
    "eta": "ETA",
    "extra_charges": "Extra Charges",
    "locomotive_with_wires_html": "Locomotive Wires (%{wires})",
    "on_deck_teus_with_value_html": "On Deck TEUS: %{quantity}",
    "optional_charges": "Optional Charges",
    "pax_day": "PAX Daylight Transit",
    "placeholder": "Enter Vessel IMO",
    "regular_charges": "Regular Charges",
    "start_here": "Start here",
    "subtotal": "Subtotal",
    "title": "Panama Canal Transit Cost Calculator",
    "tolls": "Tolls (TTL = %{quantity})",
    "total": "Total",
    "total_amount": "Total Amount",
    "ttl_html": "TTL = %{quantity}",
    "tug_services": "Tug Service Charges",
    "vessel_inspection": "Vessel Inspection",
    "vessel_type": "Vessel Type",
    "visit_creation_fee": "Visit Creation Fee",
    "water_surcharges": "Water Surcharge",
    "water_surcharges_with_date_pcent_html": "Water Surcharge <br> (%{pcent} - %{date})"
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "count_records": "%{count} records",
    "loading": "Loading...",
    "new_version": "%{model} New Version",
    "no": "No",
    "processing": "Processing...",
    "yes": "Yes"
  },
  "compare": {
    "compare_years_24_25": "Do you want to compare with 2024 or 2025?",
    "compare_years_25": "Do you want to compare with 2025?",
    "new_structure": "Do you want to compare with 2023, 2024 or 2025?",
    "old_structure": "Do you want to compare with 2022?",
    "passenger_vessels_new_structure": "Do you want to compare with 2022, 2024 or 2025?",
    "passenger_vessels_old_structure": "Do you want to compare with 2022 or 2023?"
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Seconds",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "expired": "Your account has expired due to inactivity. Please contact the site administrator.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "session_limited": "Your login credentials were used in another browser. Please sign in again to continue in this browser.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "invalid_captcha": "The captcha input was invalid.",
    "invalid_security_question": "The security question answer was invalid.",
    "mailer": {
      "confirmation_instructions": {
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "subject": "Email Changed"
      },
      "password_change": {
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "paranoid_verification_code": {
      "show": {
        "submit": "Submit",
        "submit_verification_code": "Submit verification code",
        "verification_code": "Verification code"
      }
    },
    "paranoid_verify": {
      "code_required": "Please enter the code our support team provided"
    },
    "password_expired": {
      "change_required": "Your password is expired. Please renew your password.",
      "show": {
        "change_my_password": "Change my password",
        "current_password": "Current password",
        "new_password": "New password",
        "new_password_confirmation": "Confirm new password",
        "renew_your_password": "Renew your password"
      },
      "updated": "Your new password is saved."
    },
    "passwords": {
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "unlocks": {
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "carrierwave_download_error": "could not be downloaded",
      "carrierwave_integrity_error": "is not of an allowed file type",
      "carrierwave_processing_error": "failed to be processed",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "content_type_allowlist_error": "You are not allowed to upload %{content_type} files, allowed types: %{allowed_types}",
      "content_type_denylist_error": "You are not allowed to upload %{content_type} files",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "equal_to_current_password": "must be different than the current password.",
      "equal_to_email": "must be different than the email.",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "extension_allowlist_error": "You are not allowed to upload %{extension} files, allowed types: %{allowed_types}",
      "extension_denylist_error": "You are not allowed to upload %{extension} files, prohibited types: %{prohibited_types}",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "max_size_error": "File size should be less than %{max_size}",
      "min_size_error": "File size should be greater than %{min_size}",
      "mini_magick_processing_error": "Failed to manipulate with MiniMagick, maybe it is not an image? Original Error: %{e}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": {
        "one": "1 error prohibited this %{resource} from being saved:",
        "other": "%{count} errors prohibited this %{resource} from being saved:"
      },
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "password_complexity": {
        "digit": {
          "one": "must contain at least one digit",
          "other": "must contain at least %{count} numerals"
        },
        "lower": {
          "one": "must contain at least one lower-case letter",
          "other": "must contain at least %{count} lower-case letters"
        },
        "symbol": {
          "one": "must contain at least one special character",
          "other": "must contain at least %{count} special characters"
        },
        "upper": {
          "one": "must contain at least one upper-case letter",
          "other": "must contain at least %{count} upper-case letters"
        }
      },
      "present": "must be blank",
      "record_no_found": "Ups. record not found.",
      "required": "must exist",
      "rmagick_processing_error": "Failed to manipulate with rmagick, maybe it is not an image?",
      "taken": "has already been taken",
      "taken_in_past": "was used previously.",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "vips_processing_error": "Failed to manipulate with vips, maybe it is not an image? Original Error: %{e}",
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    }
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "footer": {
    "x_results": {
      "one": "%{count} record",
      "other": "%{count} records"
    }
  },
  "helpers": {
    "page_entries_info": {
      "entry": {
        "one": "entry",
        "other": "entries",
        "zero": "entries"
      },
      "more_pages": {
        "display_entries": "Displaying %{entry_name} <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
      },
      "one_page": {
        "display_entries": {
          "one": "Displaying <b>1</b> %{entry_name}",
          "other": "Displaying <b>all %{count}</b> %{entry_name}",
          "zero": "No %{entry_name} found"
        }
      }
    },
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "links": {
    "logout": "Logout",
    "my_profile": "My profile"
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%",
        "precision": 2
      }
    },
    "precision": {
      "format": {
        "delimiter": "",
        "precision": 2
      }
    }
  },
  "pdf": {
    "calculator_results": "Calculator Results",
    "details_line_html": "Here are the details of your calculation with <span class=\"fw-bold\">Trausty® Panama Canal Toll Calculator.</span>",
    "footer": {
      "powered_by_html": "Powered by Trausty® - The Port Advisor",
      "saludate_one_html": "The displayed values are for reference only.",
      "saludate_two_html": "Any information or content can be subject to change without prior notice."
    },
    "greetings_html": "Hello, <span class=\"fw-bold\">%{fullname}</span>",
    "inputs": {
      "other_variables": "Other variables for \"%{category}\" vessels",
      "transit_details": "Transit Details",
      "vessel_details": "Vessel Details"
    },
    "results": {
      "all_total": "Total",
      "total_expenses": "TOTAL ESTIMATED EXPENSES (USD)",
      "total_extra_charges": "Subtotal:",
      "total_optional_charges": "Subtotal:",
      "total_regular_charges": "Subtotal:",
      "water_surcharges_with_date_pcent_html": "Water Surcharge (%{pcent} - %{date})"
    },
    "total_html": "Total: <u>%{total}</u>"
  },
  "ransack": {
    "all": "all",
    "and": "and",
    "any": "any",
    "asc": "ascending",
    "attribute": "attribute",
    "combinator": "combinator",
    "condition": "condition",
    "desc": "descending",
    "or": "or",
    "predicate": "predicate",
    "predicates": {
      "blank": "is blank",
      "cont": "contains",
      "cont_all": "contains all",
      "cont_any": "contains any",
      "does_not_match": "doesn't match",
      "does_not_match_all": "doesn't match all",
      "does_not_match_any": "doesn't match any",
      "end": "ends with",
      "end_all": "ends with all",
      "end_any": "ends with any",
      "eq": "equals",
      "eq_all": "equals all",
      "eq_any": "equals any",
      "false": "is false",
      "gt": "greater than",
      "gt_all": "greater than all",
      "gt_any": "greater than any",
      "gteq": "greater than or equal to",
      "gteq_all": "greater than or equal to all",
      "gteq_any": "greater than or equal to any",
      "in": "in",
      "in_all": "in all",
      "in_any": "in any",
      "lt": "less than",
      "lt_all": "less than all",
      "lt_any": "less than any",
      "lteq": "less than or equal to",
      "lteq_all": "less than or equal to all",
      "lteq_any": "less than or equal to any",
      "matches": "matches",
      "matches_all": "matches all",
      "matches_any": "matches any",
      "not_cont": "doesn't contain",
      "not_cont_all": "doesn't contain all",
      "not_cont_any": "doesn't contain any",
      "not_end": "doesn't end with",
      "not_end_all": "doesn't end with all",
      "not_end_any": "doesn't end with any",
      "not_eq": "not equal to",
      "not_eq_all": "not equal to all",
      "not_eq_any": "not equal to any",
      "not_in": "not in",
      "not_in_all": "not in all",
      "not_in_any": "not in any",
      "not_null": "is not null",
      "not_start": "doesn't start with",
      "not_start_all": "doesn't start with all",
      "not_start_any": "doesn't start with any",
      "null": "is null",
      "present": "is present",
      "start": "starts with",
      "start_all": "starts with all",
      "start_any": "starts with any",
      "true": "is true"
    },
    "search": "search",
    "sort": "sort",
    "value": "value"
  },
  "sessions": {
    "select_country": "Select Country",
    "select_occupation": "Select Occupation"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "tariff": {
    "capacity_tariff": "Capacity Tariff Price",
    "capacity_tta": "Capacity Price TTA (per TEU)",
    "empty_tte": "Empty TTE Price (per TEU)",
    "fixed": "Fixed Tariff Price (per transit)",
    "loaded_ttl": "Loaded TTL Price (per TEU)",
    "teu_dry": "TEU Dry Price",
    "teu_empty": "TEU Empty Price",
    "teu_refer": "TEU Reefer Price"
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "tooltips": {
    "calculate": "Estimate transit’s costs",
    "compare_tariffs": "Compare tariffs in the future",
    "displacement": "The vessel's weight when fully loaded.",
    "generate_proforma": "Generate transit charges proforma",
    "loyalty_program": "TTA accumulated in the last 12 months",
    "oil_capacity": "The sum of the maximum capacities of fuel system tanks.",
    "water_surcharge": "Water surcharge is an estimate based on a 45 days projection"
  },
  "unit": {
    "ltons": "long tons",
    "m3": "m3",
    "meters": "meters",
    "mtons": "mtons",
    "teus": "TEUS",
    "tons": "tons"
  },
  "user": {
    "edit_profile": "Edit Profile"
  },
  "vessel": {
    "create": "Calculate with a new vessel",
    "list": "Search",
    "placeholder": "Enter Vessel IMO or Name and press ENTER",
    "search": "Search IMO / Vessel Name"
  },
  "views": {
    "pagination": {
      "first": "&laquo; First",
      "last": "Last &raquo;",
      "next": "Next &rsaquo;",
      "previous": "&lsaquo; Prev",
      "truncate": "&hellip;"
    }
  }
});
